import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router/composables'
import { Order } from '/~/types/api'
import { formatDate } from '/~/utils/format/date'
import { useActivity } from '/~/composables/activity'
import { useLocalization } from '/~/composables/localization'
import { useProvider } from '/~/composables/provider'
import { usePointsPrograms } from '/~/templates/bill-payments/composables'

export function usePointsTransferSuccessfulComponent(props: {
  number: string
}) {
  const router = useRouter()
  const { providerTitle } = useProvider()
  const { calculatePoints, findPartner } = usePointsPrograms()
  const { getPointsOrderItem } = useActivity()
  const { formatBusinessNumber } = useLocalization()

  const transfer = ref<Order | undefined | null>(null)
  const pointsSpent = ref<string | null>(null)
  const pointsReceived = ref<number | null>(null)
  const selectedPartner = ref<any>({})

  onMounted(async () => {
    transfer.value = await getPointsOrderItem(props.number)
    pointsSpent.value = transfer.value?.paymentMethods[0].points || null
    const { data } = await calculatePoints({
      type: transfer.value?.member.type,
      amount: transfer.value?.paymentMethods[0].amount,
    })

    pointsReceived.value = data.programPoints
    selectedPartner.value = findPartner(transfer.value?.member.type)
  })

  const statusCompleted = computed(() =>
    ['completed'].includes(transfer.value?.status ?? '')
  )
  const statusPending = computed(() =>
    ['pending', 'processing'].includes(transfer.value?.status ?? '')
  )
  const statusFailed = computed(() =>
    ['failed'].includes(transfer.value?.status ?? '')
  )

  const title = computed(() => {
    if (statusCompleted.value) return 'Transfer is successful'
    if (statusPending.value) return 'Transfer is processing'
    if (statusFailed.value) return 'Transfer failed'
    return ''
  })

  const icon = computed(() => {
    if (statusCompleted.value) {
      return 'heroicons/solid/check-circle'
    } else if (statusPending.value) {
      return 'heroicons/solid/clock'
    }
    return 'heroicons/solid/x-circle'
  })

  const iconColor = computed(() => {
    if (statusCompleted.value) {
      return 'text-fg-success'
    } else if (statusPending.value) {
      return 'text-fg-warning'
    }
    return 'text-fg-error'
  })

  const formattedDate = computed(() => {
    return formatDate(
      'daymonthyearlongtime',
      transfer.value?.completedAt || transfer.value?.createdAt
    )
  })

  const partner = computed(() => {
    const { member: partner } = transfer.value

    if (!partner) return { abn: '' }

    return {
      ...partner,
      type: partner.type.toUpperCase(),
    }
  })

  const partnerIcon = computed(() => {
    return selectedPartner.value.listIcon || selectedPartner.value.icon
  })

  const partnerName = computed(() => {
    const { firstName, lastName } = partner.value

    return firstName === lastName ? firstName : `${firstName} ${lastName}`
  })

  function onLeave() {
    router.push({ name: 'home' })
  }

  onBeforeUnmount(() => {
    transfer.value = null
  })

  return {
    transfer,
    iconColor,
    icon,
    title,
    formattedDate,
    statusFailed,
    selectedPartner,
    partnerIcon,
    partnerName,
    formatBusinessNumber,
    partner,
    providerTitle,
    pointsSpent,
    pointsReceived,
    router,
    onLeave,
  }
}
